import "./App.css";
import Navbar from "./components/Navbar";
import Title from "./components/Title";
import Summary from "./components/Summary";
import ProblemStatement from "./components/ProblemStatement";
import SolutionStatement from "./components/SolutionStatement";
import Statistics from "./components/Statistics";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import { useEffect, useRef } from "react";
import { Grid } from "@mui/material";

function App() {
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    sectionRefs.current.forEach((section) => {
      if (section) {
        section.classList.add("animate");
      }
    });
  }, []);

  return (
    <div className="App">
      <Grid container justifyContent={"center"}>
        <Grid item xs={12}>
          <Navbar />
        </Grid>
        <Grid item xs={12} ref={(el) => (sectionRefs.current[0] = el)}>
          <Title />
        </Grid>
        <Grid item xs={12} md={10} ref={(el) => (sectionRefs.current[1] = el)}>
          <Summary />
        </Grid>
        <Grid item xs={12} md={10} ref={(el) => (sectionRefs.current[2] = el)}>
          <ProblemStatement />
        </Grid>
        <Grid item xs={12} md={10} ref={(el) => (sectionRefs.current[3] = el)}>
          <SolutionStatement />
        </Grid>
        <Grid item xs={12} ref={(el) => (sectionRefs.current[4] = el)}>
          <Statistics />
        </Grid>
        <Grid item xs={12} ref={(el) => (sectionRefs.current[5] = el)}>
          <Contact />
        </Grid>
        <Grid item xs={12} ref={(el) => (sectionRefs.current[6] = el)}>
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
