import {
  Grid,
  Typography,
  Paper,
  useMediaQuery,
  useTheme,
} from "@mui/material";

function Title(): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Paper
        sx={{
          backgroundColor: "#0B0B0B",
          height: isSmallScreen ? "350px" : "250px",
        }}
        elevation={0}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item xs={11} style={{ textAlign: "center" }} marginTop={6}>
            <Typography
              style={{
                color: "#FFDB58",
                fontSize: "40px",
                fontWeight: "bolder",
              }}
              variant={"h1"}
              marginY={2}
            >
              Cryptographic Data Mesh
              <Typography
                component="span"
                variant="h6"
                style={{ verticalAlign: "super", color: "#FFDB58" }}
              >
                ®
              </Typography>
            </Typography>
            <Grid item>
              <Typography
                style={{
                  color: "white",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
                variant={"h2"}
              >
                Health data made connected, usable and cryptographically
                authentic
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

export default Title;
