// Social Media Links
export const acoerLinkedIn = "https://www.linkedin.com/company/acoer/";
export const acoerYoutube = "https://www.youtube.com/@acoerco";
export const acoerTwitter = "https://twitter.com/acoerco?lang=en";

// Website Links
export const cryptographicDataMeshAcoerWebsite = "https://www.acoer.com";
export const acoerWebsite = "https://www.acoer.com/";
export const acoerContact = "https://www.acoer.com/company/contact";
export const acoerAbout = "https://www.acoer.com/company/about";
