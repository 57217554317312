import React from "react";
import { Paper, Box } from "@mui/material";
import ReactPlayer from "react-player";

const Video: React.FC = () => {
  return (
    <Paper
      style={{ height: "100%", width: "100%", position: "relative" }}
      elevation={0}
    >
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <ReactPlayer
          url={"https://www.youtube.com/embed/ldgc4LJ3V48?si=I_0UsDySVx7x9zbh"}
          controls={true}
          style={{
            borderRadius: "50%",
            display: "block",
            margin: "auto",
            maxWidth: "100%",
          }}
        />
      </Box>
    </Paper>
  );
};

export default Video;
