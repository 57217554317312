import "../App.css";
import {
  Link,
  AppBar,
  Toolbar,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { acoerContact } from "../assets/links/links";

function Contact(): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <AppBar position="static" elevation={1}>
        <Toolbar
          className="nav-bar"
          sx={{
            paddingBottom: "30px",
            paddingTop: "30px",
            borderBottom: "1px solid gray",
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            padding={6}
            spacing={isSmallScreen ? 0 : 2}
          >
            <Grid item>
              <Typography
                sx={{
                  color: "#FFF",
                  fontSize: "40px",
                  fontWeight: "bolder",
                  marginY: 2,
                }}
              >
                Have questions or want to work together?
              </Typography>
            </Grid>
            <Grid item>
              <Link href={acoerContact} target="_blank" rel="noreferrer">
                <Button
                  className="contact-button"
                  size="large"
                  sx={{
                    fontSize: "1.2rem",
                    fontWeight: "bold",
                    borderRadius: "50px",
                    border: "2px solid #FFDB58",
                    color: "#FFDB58",
                    textTransform: "none",
                    transition: "background-color 0.3s, color 0.3s",
                    padding: "20px 20px",
                    "&:hover": {
                      backgroundColor: "#FFDB58",
                      color: "black",
                    },
                  }}
                >
                  Contact Us
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Contact;
