import { Box, Grid, Paper, Typography } from "@mui/material";
import React from "react";

const ProblemStatement: React.FC = () => {
  return (
    <Paper elevation={0}>
      <Box paddingX={4} paddingTop={10}>
        <Typography sx={{ color: "#e8c658" }} variant="h6" marginBottom={4}>
          Problem
        </Typography>
        <Grid container alignItems="center" spacing={3} marginBottom={4}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              Health data is siloed and isolated.
            </Typography>
            <Typography paragraph>
              In the rapidly evolving landscape of data management,
              organizations face significant challenges related to data
              security, compliance, and operational efficiency. Existing systems
              often struggle with issues such as outdated technology, siloed
              data, and high costs associated with data centralization and
              migration. These challenges are further compounded by the
              increasing need for scalable solutions that can efficiently
              process large volumes of data while maintaining compliance with
              regulatory standards such as HIPAA.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Opacity
            </Typography>
            <Typography paragraph>
              Opacity in data management hinders verification, compliance, and
              insight generation, undermining operational efficiency and
              stakeholder trust.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Redundancy
            </Typography>
            <Typography paragraph>
              Redundancy in data management leads to inefficiencies and
              increased costs, complicating data verification and consistency.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              High Costs
            </Typography>
            <Typography paragraph>
              High costs in data centralization stem from extensive migration
              processes and the development of proprietary solutions, burdening
              organizations financially and operationally.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
              Burdens on Patients and Physicians
            </Typography>
            <Typography paragraph>
              Data centralization burdens patients and physicians by causing
              delays and inefficiencies in accessing timely and accurate medical
              information, impacting the quality of care.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default ProblemStatement;
