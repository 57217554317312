import React from "react";
import {
  Paper,
  Typography,
  Grid,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { IoIosLock } from "react-icons/io";
import { GiReceiveMoney } from "react-icons/gi";
import { FaHeartbeat } from "react-icons/fa";

const SummaryList: React.FC = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Paper elevation={0}>
      <Grid container justifyContent={"center"} alignItems="center">
        <Grid item xs={11} md={10} marginTop={6}>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item>
              <div className="icon-container">
                <IoIosLock style={{ color: "#FFDB58", fontSize: "25px" }} />
              </div>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                Securing Data
              </Typography>
              <Typography>
                Our Cryptographic Data Mesh® allows data to remain in place,
                ensuring adherence to current data security protocols and HIPAA
                standards.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={11} md={10} marginTop={6}>
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item>
              <div className="icon-container">
                <GiReceiveMoney
                  style={{ color: "#FFDB58", fontSize: "25px" }}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                Reducing Costs
              </Typography>
              <Typography>
                By minimizing the need for extensive data migration, our
                architecture significantly reduces costs and leverages existing
                infrastructure.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={11}
          md={10}
          marginTop={6}
          marginBottom={isSmallScreen ? 6 : 0}
        >
          <Grid container alignItems={"center"} spacing={2}>
            <Grid item>
              <div className="icon-container">
                <FaHeartbeat style={{ color: "#FFDB58", fontSize: "22px" }} />
              </div>
            </Grid>
            <Grid item xs={12} md={10}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
                Insights That Can Save Lives
              </Typography>
              <Typography>
                By connecting diverse data sources into a unified architecture,
                our solution enhances insights and enables better
                decision-making, with secure analytics and AI discovery on
                decentralized, validated health data systems.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SummaryList;
