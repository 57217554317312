import { Grid, Link, Typography } from "@mui/material";
import twitterImg from "../assets/images/TwitterImg.svg";
import youtubeImg from "../assets/images/YoutubeImg.svg";
import linkedInImg from "../assets/images/LinkedInImg.svg";
import acoerLogo from "../assets/images/acoer_logo_black.svg";
import {
  acoerLinkedIn,
  acoerTwitter,
  acoerWebsite,
  acoerYoutube,
} from "../assets/links/links";

function Footer(): JSX.Element {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      padding={4}
    >
      <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
        <Link href={acoerWebsite} target="_blank" rel="noreferrer">
          <img src={acoerLogo} alt="Acoer-logo" style={{ maxWidth: "100%" }} />
        </Link>
        <Typography variant="h6">
          Delivering secure, usable, and real-time blockchain solutions to
          transform healthcare, empower innovation, and enhance lives.
        </Typography>
      </Grid>
      <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
        <Grid container justifyContent="center">
          <Grid item padding={2}>
            <Link href={acoerTwitter} target="_blank" rel="noreferrer">
              <img
                src={twitterImg}
                height={60}
                width={60}
                alt="twitter-icon"
                className="social-media-button"
              />
            </Link>
          </Grid>
          <Grid item padding={2}>
            <Link href={acoerLinkedIn} target="_blank" rel="noreferrer">
              <img
                src={linkedInImg}
                height={60}
                width={60}
                alt="linkedin-icon"
                className="social-media-button"
              />
            </Link>
          </Grid>
          <Grid item padding={2}>
            <Link href={acoerYoutube} target="_blank" rel="noreferrer">
              <img
                src={youtubeImg}
                height={60}
                width={60}
                alt="youtube-icon"
                className="social-media-button"
              />
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} style={{ textAlign: "center", marginTop: "4rem" }}>
        <Typography variant="caption">
          Copyright © 2014-2024 | All Rights Reserved
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
