/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import healthStaff from "../assets/images/healthcare-staff.png";
import dataMesh from "../assets/images/video-thumbnail.png";
import checkMark from "../assets/images/check-mark.png";

const SolutionStatement: React.FC = () => {
  return (
    <Paper elevation={0}>
      <Box padding={4}>
        <Typography sx={{ color: "#e8c658" }} variant="h6" marginBottom={4}>
          Solution
        </Typography>
        <Grid container alignItems="center" spacing={3} marginBottom={4}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
              Connecting Healthcare Data with Acoer's Cryptographic Data Mesh{" "}
              <Typography
                component="span"
                variant="h6"
                style={{ marginLeft: "-10px", verticalAlign: "super" }}
              >
                ®
              </Typography>
            </Typography>
            <Typography paragraph>
              Acoer recognizes these challenges and the pressing need for
              innovative solutions that can address them effectively. Our
              extensive experience in developing secure and scalable data
              management solutions positions us uniquely to tackle these issues.
              By leveraging our expertise, we are introducing Cryptographic Data
              Mesh® that provides a robust framework for secure, efficient, and
              compliant data management. See Acoer’s Cryptographic Data Mesh®
              highlighted in our Hedera Use Cases{" "}
              <a
                href="https://hedera.com/users/acoer#:~:text=To%20address%20this%20challenge%2C%20Acoer,authenticity%20and%20most%20importantly%20%2D%20privacy"
                target={"_blank"}
                rel={"noreferrer"}
              >
                here
              </a>
              .
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} lg={4}>
            <img
              src={dataMesh}
              alt="data mesh image"
              width="100%"
              height="200px"
              style={{ objectFit: "contain" }}
            />
            <Typography
              variant="h6"
              paddingY={2}
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              De-silo health data without costly centralization
            </Typography>
            <Typography paragraph>
              Our Cryptographic Data Mesh® reduces costs by minimizing the need
              for extensive data migration and lowering infrastructure expenses
              through decentralized storage.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <img
              src={checkMark}
              alt="check mark image"
              width="100%"
              height="200px"
              style={{ objectFit: "contain", transform: "scale(0.80)" }}
            />
            <Typography
              variant="h6"
              paddingY={2}
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Cryptographically prove data authenticity
            </Typography>
            <Typography paragraph>
              Incorporating zero-knowledge proofs, our architecture ensures
              cryptographically authentic, consented, and verifiable data flow
              to upstream systems, safeguarding data owner rights.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5} lg={4}>
            <img
              src={healthStaff}
              alt="health staff"
              width="100%"
              height="200px"
              style={{ objectFit: "contain" }}
            />
            <Typography
              variant="h6"
              paddingY={2}
              gutterBottom
              sx={{ fontWeight: "bold" }}
            >
              Increase trust and confidence in health data
            </Typography>
            <Typography paragraph>
              Our Cryptographic Data Mesh® secures data by keeping it
              decentralized and encrypted, ensuring compliance with stringent
              security protocols and privacy standards like HIPAA.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default SolutionStatement;
