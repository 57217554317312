/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";

const Statistics: React.FC = () => {
  return (
    <Paper style={{ backgroundColor: "#E0E0E0" }} elevation={0}>
      <Grid container justifyContent={"center"}>
        <Grid item xs={12} md={10}>
          <Box padding={4}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={5} lg={4}>
                <Typography
                  variant="h1"
                  paddingBottom={2}
                  sx={{ fontWeight: "bold" }}
                >
                  70%
                </Typography>
                <Typography
                  variant="h6"
                  paddingBottom={2}
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Reduction in Data Migration Costs
                </Typography>
                <Typography paragraph>
                  By eliminating the need for extensive data migration,
                  organizations can reduce costs by up to 70%.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <Typography
                  variant="h1"
                  paddingBottom={2}
                  sx={{ fontWeight: "bold" }}
                >
                  50%
                </Typography>
                <Typography
                  variant="h6"
                  paddingBottom={2}
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Lower Cost of Revalidation of Health Systems
                </Typography>
                <Typography paragraph>
                  By maintaining decentralized and secure data, which minimizes
                  the need for repeated validation processes.
                </Typography>
              </Grid>
              <Grid item xs={12} md={5} lg={4}>
                <Typography
                  variant="h1"
                  paddingBottom={2}
                  sx={{ fontWeight: "bold" }}
                >
                  60%
                </Typography>
                <Typography
                  variant="h6"
                  paddingBottom={2}
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Reduction in Compliance and Privacy Costs
                </Typography>
                <Typography paragraph>
                  Implementing zero-knowledge proofs and decentralized
                  identifiers enhances compliance with regulations like HIPAA,
                  reducing compliance costs by up to 60%.
                </Typography>
              </Grid>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item>
                <Typography paddingTop={4} variant="inherit">
                  * based on initial Acoer use cases with cancer and novel
                  pathogen data from public and private data sources
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Statistics;
