import "../App.css";
import {
  Link,
  AppBar,
  Toolbar,
  Grid,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import {
  acoerWebsite,
  acoerContact,
  acoerAbout,
  cryptographicDataMeshAcoerWebsite,
} from "../assets/links/links";
import acoerLogo from "../assets/images/acoer_logo.svg";

function Header(): JSX.Element {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <AppBar position="static" elevation={1}>
        <Toolbar
          className="nav-bar"
          sx={{
            paddingBottom: "10px",
            paddingTop: "10px",
            borderBottom: "1px solid gray",
          }}
        >
          <Grid
            container
            justifyContent="space-evenly"
            alignItems="center"
            spacing={isSmallScreen ? 0 : 8}
          >
            <Grid item>
              <Link href={acoerWebsite} target="_blank" rel="noreferrer">
                <img src={acoerLogo} alt="Acoer-logo" />
              </Link>
            </Grid>
            {isSmallScreen ? null : <Grid item />}
            <Grid item>
              <Grid container spacing={6}>
                <Grid item>
                  <Link
                    href={cryptographicDataMeshAcoerWebsite}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      className="button-with-outline"
                      size="large"
                      sx={{ color: "white" }}
                    >
                      Home
                    </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href={acoerAbout} target="_blank" rel="noreferrer">
                    <Button
                      className="button-with-outline"
                      size="large"
                      sx={{ color: "white" }}
                    >
                      About
                    </Button>
                  </Link>
                </Grid>
                <Grid item>
                  <Link href={acoerContact} target="_blank" rel="noreferrer">
                    <Button
                      className="button-with-outline"
                      size="large"
                      sx={{ color: "white" }}
                    >
                      Contact
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default Header;
