import React from "react";
import { Grid } from "@mui/material";
import SummaryList from "./SummaryList";
import Video from "./Video";

const Summary: React.FC = () => {
  return (
    <Grid container sx={{ mt: 4 }} justifyContent={"center"}>
      <Grid item md={7} xs={12}>
        <SummaryList />
      </Grid>
      <Grid item md={5} xs={11}>
        <Video />
      </Grid>
    </Grid>
  );
};

export default Summary;
